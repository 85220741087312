<template>
<p></p>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: "Logout",
  setup(){
    const router = useRouter()
    const store = useStore()
    store.dispatch('Logout')
    router.push({ name: 'Home' })
  }
}
</script>




